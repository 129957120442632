.navBarClass {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
}

.navWrapper {
    text-align: right;
}

.navItem {
    text-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    margin: 1vh 1vw;
    display: inline;
    margin: 5px;
    text-decoration: none;
    cursor: pointer;
}

.navBarClass .navItem:hover {
    background-color: var(--redPrimary);
    color: white;
}

.navBarClass .navItem:active {
    background-color: rgb(255,50,50);
}

.menuWithBg {
    box-shadow: 0 4px 7px rgba(0, 0, 0, 0.16);
    border-radius: 5px;
    background-color: #fff;
    color: var(--redPrimary);
    padding: 7px 13px;
    margin-right: 0;
}

.navMobile {
    display: none;
}

.blackShadow {
    display: none;
}

@media all and (max-width: 768px) {
    .navBarClass {
        display: none;
    }

    .navMobile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px;
        margin-bottom: 12px;
    }

    .logoWrapper img {
        width: 150px;
    }

    .hamburgerIcon img {
        width: 30px;
    }

    .menuList {
        display: none;
        position: absolute;
        top: 23px;
        left: 55%;
        background-color: white;
        border-radius: 5px;
        padding: 5px 20px;
    }

    .menuItem {
        display: block;
        margin: 20px 0;
        text-decoration: none;
        color: var(--redPrimary);
        font-weight: 500;
    }

    .blackShadow {
        background-color: rgba(0, 0, 0);
        width: 150vw;
        height: 150vh;
        position: fixed;
        left: -3%;
        top: -3%;
        z-index: 0;
        opacity: 0.5;
    }
}