.footerMenuClass {
    color: white;
    margin: auto;
    font-size: smaller;
    margin-top: 30px;
    display: flex;
}

.footerMenuClassItems {
    /* width: 150px; */
    text-align: center;
    margin: 0 10px;
}

.footerMenuWrapper {
    display: flex;
    width: 500px;
    margin: auto;
    text-align: center;
    justify-content: space-around;
}

.footerMenuWithBg {
    background-color: white;
    padding: 8px 16px;
    border-radius: 28px;
    color: var(--redPrimary);
}

@media all and (max-width: 768px) {
    .footerMenuClass {
        display: block;
    }

    .footerMenuWrapper {
        display: block;
        width: 100%;
    }

    .footerMenuClassItems {
        text-align: left;
        margin: 12px 5px;
    }

    .navItem {
        margin: 0;
        font-size: 12px;
    }    
}