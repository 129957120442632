.inputResult {
    display: flex;
}

.adminClass input {
    width: 150px;
    border-radius: 6px;
    outline: none;
    padding: 10px 15px;
    margin-right: 10px;
}

.adminClass h1 {
    color: var(--redPrimary);
    margin-bottom: 20px;
}

.goLiveButton {
    display: flex;
    align-items: center;
    background-color: var(--redPrimary);
    color: white;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    box-sizing: border-box;
}

.adminClass {
    background-color: white;
    padding: 50px 30px;
    border-radius: 10px;
}

@media all and (max-width: 768px) {
    .adminClass {
        padding: 25px 15px;
        margin-top: 9px;
    }

    .adminClass table {
        margin-top: 25px;
    }
}