.loginClass {
    display: flex;
    align-content: center;
    height: 88vh;
}

.formBox {
    background-color: white;
    width: 30%;
    margin: auto;
    border-radius: 5px;
    text-align: center;
    padding: 20px;
    box-sizing: border-box;
}

.formTitle {
    color: #ce2b37;
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 15px;
}

.loginClass input {
    width: 70%;
    margin: auto;
    outline: none;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    margin: 5px 0;
}

.submitLogin {
    background-color: #ce2b37;
    color: white;
    width: 50px;
    margin: auto;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    outline: none;
    border: none;
}

@media all and (max-width: 768px) {
    .formBox {
        width: 95%;
    }
}