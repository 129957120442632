.contentContainer {
    background-color: white;
    padding: 25px;
    border-radius: 10px;
}

.titleByDayWrapper {
    color: var(--redPrimary);
    font-size: 25px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prevNextButton, .dashLine {
    font-size: 16px;
    font-weight: 400;
}

.prevNextButton:hover {
    text-decoration: underline;
    cursor: pointer;
}

@media all and (max-width: 768px) {
    .contentContainer {
        margin-top: 25px;
        padding: 5px;
        padding-bottom: 15px;
        border-radius: 5px;
    }

    .titleByDayWrapper {
        font-size: 20px;
        padding: 10px;
    }

    .prevNextButton {
        font-size: 12px;
    }

    .paddingAdjusments {
        padding: 10px 0;
    }
}