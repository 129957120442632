.liveDrawWrapper .titleWrapper {
    color: white;
    align-items: center;
}

.firstSecondThirdLiveDraw {
    width: 485px;
    height: 223px;
    box-sizing: border-box;
}

.liveDrawContainer {
    background-color: white;
    padding: 15px 35px;
    border-radius: 5px;
}

.otherLiveDraw {
    text-align: center;
    width: 225px;
    padding: 17px;
    height: 205px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.firstSecondThirdLiveNumber {
    color: var(--bluePrimary);
    font-size: 25px;
    font-weight: 700;
    width: 300px;
    height: 51px;
    text-align: left;
}

.firstSecondThirdLiveNumber img {
    width: 50px;
}

.resultGroupLive {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
    text-align: center;
    width: 415px;
    height: 51px;
}

.titleLiveNumber {
    color: var(--redPrimary);
    font-size: larger;
    font-weight: 600;
}

.otherNumberResult {
    color: var(--bluePrimary);
    margin: 12px 0;
}

.firstRow {
    margin: 30px 0;
}

.liveDrawClass h1 {
    margin-bottom: 5px;
}

.secondRow {
    display: flex;
    justify-content: space-between;
}

.otherTitle {
    color: var(--redPrimary);
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 15px;
}

.bodyWrapper {
    width: 485px;
    margin: auto;
}

.liveDrawString {
    margin-bottom: 5px;
    font-weight: bold;
}

@media all and (max-width: 768px) {
    .firstSecondThirdLiveDraw {
        width: 100%;
        padding: 10px 10px 30px 10px;
        height: 100%;
    }

    .sisaWaktu {
        margin-top: 30px;
        font-size: smaller;
        line-height: 135%;
    }

    .titleWrapper {
        padding: 10px;
    }

    .liveDrawString {
        margin-bottom: 0;
    }

    .bodyWrapper {
        width: 100%;
    }

    .titleLiveNumber {
        font-size: 14px;
    }

    .resultGroupLive {
        display: block;
        width: 100%;
    }

    .firstSecondThirdLiveNumber {
        width: 100%;
        text-align: center;
        margin-top: 5px;
    }

    .firstSecondThirdLiveNumber img {
        width: 40px;
    }

    .secondGroupLive {
        margin: 40px 0;
    }

    .otherLiveDraw {
        width: 48.5%;
        padding: 12px 4px;
        height: 100%;
    }

    .otherTitle {
        line-height: 125%;
        font-size: 16px;
    }
}