.bigTitle {
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    color: #ffffff;
    font-size: 50px;
    font-weight: 700;
    display: inline;
    margin-right: 20px;
}

.todayDate {
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    display: inline;
}

.titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.nextResultSection {
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.16);
    color: #fff;
    font-size: 17px;
    font-weight: 400;
    text-align: right;
    margin-bottom: 3px;
}

.titleNextResult {
    font-weight: 700;
    margin-bottom: 6px;
}

.firstResult {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: solid 1px #707070;
    padding-bottom: 18px;
}

.firstTitle {
    color: var(--redPrimary);
    font-size: 25px;
    font-weight: 400;
}

.firstResultNumber {
    width: 330px;
    height: 55px;
}

.firstResultNumber img {
    width: 55px;
}

.firstSecondThirdResult {
    padding: 20px;
    margin: auto;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.16);
    border-radius: 9px;
    background-color: #fff;
}

.leftSideTable {
    width: 49%;
    margin-top: 25px;
}

.secondAndThirdResult {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 10px;
}

.secondAndThirdResultNumber {
    width: 213px;
    height: 36px;
}

.secondAndThirdResultNumber img {
    width: 35.5px;
}

.secondResult, .thirdResult {
    display: flex;
    align-items: center;
}

.secondAndThirdTitle {
    color: var(--redPrimary);
    font-size: 18.75px;
    font-weight: 400;
    margin-right: 12.75px;
}

.specialResult {
    margin-top: 25px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.16);
    border-radius: 9px;
    background-color: #fff;
    width: 47.5%;
}

.specialAndConsolation {
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.specialResultTitle {
    color: var(--redPrimary);
    font-size: 25px;
    font-weight: 700;
    margin-bottom: 10px;
}

.specialResultNumber {
    color: var(--bluePrimary);
    font-size: 17.5px;
    font-weight: 400;
    margin: 13.5px 0;
}

.specialResult {
    padding: 15px;
    box-sizing: border-box;
}

.contentWrapper {
    display: flex;
    justify-content: space-between;
}

.rightSideTable {
    width: 49%;
    margin-top: 25px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.16);
    border-radius: 9px;
    background-color: #fff;
}

.tableTitle {
    color: var(--redPrimary);
    font-size: 25px;
    font-weight: 700;
}

.rightSideTable {
    padding: 30px;
    box-sizing: border-box;
}

.tableHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.seePrevious {
    text-decoration: underline;
 }

table {
    width: 100%;
    margin-top: 30px;
    border-radius: 5px;
    overflow: hidden;
}

tr:nth-child(odd) {
    background-color: rgba(242,242,242,0.3);
}

tr:nth-child(even) {
    background-color: rgba(56,181,118,0.3);
}

tr:first-child {
    background-color: rgba(206,43,55,1);
}

th, td {
    padding: 10px;
}

td {
    color: var(--bluePrimary);
    font-weight: 400;
    text-align: center;
}

th {
    color: #fff;
    font-weight: 700;
}

@media all and (max-width: 768px) {
    .titleWrapper {
        display: block;
        justify-content: unset;
    }

    .leftSectionTitle {
        display: block;
        padding: 10px;
    }

    .bigTitle {
        display: block;
        font-size: 40px;
    }

    .todayDate {
        font-size: 14px;
    }

    .nextResultSection {
        text-align: left;
        font-size: 14px;
    }

    .rightSectionTitle {
        margin-top: 20px;
        padding: 10px;
    }

    .contentWrapper {
        display: block;
    }

    .leftSideTable {
        width: 100%;
    }

    .firstSecondThirdResult {
        padding: 5px;
    }

    .firstResultNumber img {
        width: 35px;
    }

    .firstResultNumber {
        height: 100%;
        width: unset;
    }

    .firstTitle {
        font-size: 20px;
        margin-left: 10px;
        width: 28px;
        margin-right: 21px;
    }

    .firstResult {
        padding: 10px 0;
        display: flex;
        align-items: center;
        justify-content: unset;      
    }

    .secondAndThirdTitle {
        font-size: 15px;
    }

    .secondAndThirdResultNumber {
        margin-left: 12px;
    }

    .secondAndThirdResultNumber img {
        width: 28.4px;
    }

    .secondAndThirdResult {
        display: block;
    }

    .secondAndThirdTitle {
        width: 28px;
    }

    .secondResult, .thirdResult {
        margin-left: 10px;
        padding: 3px 0;
    }

    .mobileNone {
        display: none;
    }
    
    .specialResultTitle {
        font-size: 16px;
        line-height: 25px;
    }

    .specialResult {
        width: 48%;
        padding: 12px 4px;
    }

    .specialResultNumber {
        font-size: 14px;
    }

    .rightSideTable {
        width: 100%;
        padding: 7.5px;
        padding-bottom: 20px;
    }

    .tableHeader {
        display: block;
        padding: 10px;
    }

    table {
        margin-top: 10px;
    }

    .tableTitle {
        margin-bottom: 10px;
    }

    table {
        font-size: 12px;
    }
}