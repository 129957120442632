.isiParagraf {
    margin-top: 20px;
}

.isiParagraf h2 {
    font-weight: 600;
    font-size: large;
    margin: 20px 0;
}

.isiParagraf p {
    margin: 15px 0;
    line-height: 33px;
}

.isiParagraf a {
    color: var(--redPrimary)
}

.isiParagraf a:hover {
    color: var(--bluePrimary)
}

.containerOnDesktop {
    width: 950px;
    margin: auto;
}

@media all and (max-width: 768px) {
    .isiParagraf img {
        width: 100%;
    }

    .contentContainer {
        width: 100%;
        box-sizing: border-box;
    }

    .extraPadding {
        padding: 20px;
    }

    .paddingAdjusment {
        padding: 10px 0;
    }
}