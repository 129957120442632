.footerClass {
    display: flex;
    justify-content: space-between;
    background-color: white;
    border-radius: 5px;
    padding: 15px;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.16);
    font-size: smaller;
    margin-top: 30px;
}

.componentDiv {
    display: flex;
    width: 300px;
}

.footerImage {
    border-radius: 10px;
    overflow: hidden;
    margin-right: 10px;
}

.titleFooterComponent {
    font-weight: 600;
    margin-bottom: 5px;
}

.textPart {
    padding: 10px 0;
    width: 80%;
}

@media all and (max-width: 768px) {
    .footerClass {
        display: block;
        padding: 1pt 10px;
    }

    .componentDiv {
        margin: 30px 0;
    }

    .textPart {
        width: 60%;
    }
}